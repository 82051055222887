<template>
  <div class="pt-12 sm:pt-16">
    <div class="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-5xl text-center">
        <h2 class="text-3xl font-bold tracking-tight text-lightgrey-100 sm:text-4xl">
          Trusted by artists from over 80 countries<span class="text-orange-500">.</span>
        </h2>
        <p class="mt-3 text-xl text-lightgrey-200 sm:mt-4">
          Join over 20,000 artists in visual effects, film, games and architecture who rely on MattePaint.
        </p>
      </div>
    </div>
    <div class="mt-10 pb-12 sm:pb-16">
      <div class="relative">
        <div class="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="mx-auto max-w-6xl">
            <dl class="rounded-lg bg-darkgray-800 shadow-lg grid grid-cols-2 sm:grid-cols-4">
              <div class="flex flex-col border-b border-darkgray-700 p-6 text-center sm:border-0 sm:border-r">
                <dt class="order-2 mt-2 text-lg font-medium leading-6 text-lightgrey-200">
                  Assets online
                </dt>
                <dd class="order-1 text-5xl font-bold tracking-tight text-orange-500">
                  100k+
                </dd>
              </div>
              <div class="flex flex-col border-t border-b border-darkgray-700 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                <dt class="order-2 mt-2 text-lg font-medium leading-6 text-lightgrey-200">
                  Resolution
                </dt>
                <dd class="order-1 text-5xl font-bold tracking-tight text-orange-500">
                  50k+
                </dd>
              </div>
              <div class="flex flex-col border-t border-b border-darkgray-700 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                <dt class="order-2 mt-2 text-lg font-medium leading-6 text-lightgrey-200">
                  Quality
                </dt>
                <dd class="order-1 text-5xl font-bold tracking-tight text-orange-500">
                  32bit
                </dd>
              </div>
              <div class="flex flex-col border-t border-darkgray-700 p-6 text-center sm:border-0 sm:border-l">
                <dt class="order-2 mt-2 text-lg font-medium leading-6 text-lightgrey-200">
                  Categories
                </dt>
                <dd class="order-1 text-5xl font-bold tracking-tight text-orange-500">
                  50+
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>